<template>
    <div class="input-grid_wrapper">
        <div class="checkbox_wrapper">
            <span :class="'input-grid_field-' + field">
                {{ name }}<span class="input-grid_field-arrow"></span>
            </span>
            <span class="input-grid_label">{{ count }}</span>
            <input type="checkbox" :checked="isChecked" />
            <span class="checkmark"></span>
        </div>
    </div>
</template>

<script>
    import { computed, reactive } from "vue";

    export default {
        inheritAttrs: false,
        props: {
            count: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            field: {
                type: String,
                required: true,
            },
            isChecked: {
                type: Boolean,
                required: true,
            },
        },
    };
</script>

<style src="./CheckBox.scss" scoped />
