<template>
    <div class="content">
        <Menu @change-side-list="changeSideList"
              @new-search="newSearch"
              :url="callUrl"
              :w="w"
              :r="r"
              :i="i"
              :car="car"
              :plateNumber="plateNumber"
              :seasonPick="season"
              :placementPick="placement"
              :tireData="tireData"
              :isPlate="isPlate"
              :truckArray="filterArray"
              :plateList="plateList" />

        <PopUp v-if="popupVisible" :data="data" @close-list="closeList" />

        <List @open-list="openList"
              @active-list="activeList"
              @open-equip="openEquip"
              @sorting-array="sortingArray"
              @load-more=""
              :list-loading="listLoading"
              :loop-array="loopArray"
              :list-status="listStatus"
              :sort-array="sortArray" />

        <div class="side-list scrollbar-style"
             :class="sideListShown ? ' side-list--open' : ' side-list--closed'">
            <div class="side-list_group"
                 v-for="(sideListData, index1) in filterArray"
                 :key="'sideListData-' + index1">
                <h3 class="side-list_headline" v-if="sideListData.name !== 'Lasbil'">{{ sideListData.name }}</h3>

                <div class="side-list_check-wrap"
                     @click="filterClick(index1, index2)"
                     v-for="(checkboxData, index2) in sideListData.data">
                    <CheckBox :key="'sideListCheckbox-' + index2"
                              :count="'(' + checkboxData.count + ')'"
                              :name="checkboxData.name"
                              :isChecked="checkboxData.selected"
                              :field="checkboxData.type" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { onMounted, ref } from "vue";
    import { url } from "./index";
    import Topmenu from "/@components/Topmenu/Topmenu.vue";
    import List from "/@components/List/List.vue";
    import Menu from "/@components/Menu/Menu.vue";
    import PopUp from "/@components/PopUp/PopUp.vue";
    import CheckBox from "/@components/CheckBox/CheckBox.vue";
    import sortArrayData from "/@assets/mockdata/sortArray.json";
    import { router } from "./index";
    import { url } from "./index";

    const sortArray = sortArrayData;

    export default {
        components: {
            Topmenu,
            Menu,
            List,
            PopUp,
            CheckBox,
        },
        setup(props)
        {
            const callUrl = ref(url);
            const car = ref('');
            const data = ref({});
            const filterArray = ref([]);
            const fullArray = ref([]);
            const hiddenArray = ref([]);
            const i = ref('0');
            const isPlate = ref(false);
            const listLoading = ref(true);
            const listNumber = ref(0);
            const listSize = ref(20);
            const listStatus = ref(0);
            const loopActive = ref(1000000);
            const loopArray = ref([]);
            const loopLastActive = ref(1000000);
            const plateList = ref([]);
            const plateNumber = ref('');
            const popupVisible = ref(false);
            const r = ref('0');
            const season = ref('S');
            const placement = ref('');
            const sideListShown = ref(false);
            const sortReverse = ref(false);
            const tireData = ref(false);
            const w = ref('0');

            const sortingArray = (index) =>
            {
                const sortBy = sortArray[index].name;
                const modSearchResult = JSON.parse(JSON.stringify(loopArray.value))
                let sortResult = [];

                if (sortBy == 'Beskrivelse')
                {
                    if (sortReverse.value)
                    {
                        sortResult = modSearchResult.sort((a, b) => (a.description.text > b.description.text ? 1 : -1));
                    }
                    else
                    {
                        sortResult = modSearchResult.sort((a, b) => (b.description.text > a.description.text ? 1 : -1));
                    }
                }
                if (sortBy == 'Mærke')
                {
                    if (sortReverse.value)
                    {
                        sortResult = modSearchResult.sort((a, b) => (a.brand.text > b.brand.text ? 1 : -1));
                    }
                    else
                    {
                        sortResult = modSearchResult.sort((a, b) => (b.brand.text > a.brand.text ? 1 : -1));
                    }
                }
                if (sortBy == 'Brændstof')
                {
                    if (sortReverse.value)
                    {
                        sortResult = modSearchResult.sort((a, b) => (a.fuel.type > b.fuel.type ? 1 : -1));
                    }
                    else
                    {
                        sortResult = modSearchResult.sort((a, b) => (b.fuel.type > a.fuel.type ? 1 : -1));
                    }
                }
                if (sortBy == 'Vejgreb')
                {
                    if (sortReverse.value)
                    {
                        sortResult = modSearchResult.sort((a, b) => (a.wet.type > b.wet.type ? 1 : -1));
                    }
                    else
                    {
                        sortResult = modSearchResult.sort((a, b) => (b.wet.type > a.wet.type ? 1 : -1));
                    }
                }
                if (sortBy == 'Støj')
                {
                    if (sortReverse.value)
                    {
                        sortResult = modSearchResult.sort((a, b) => (a.sound.type > b.sound.type ? 1 : -1));
                    }
                    else
                    {
                        sortResult = modSearchResult.sort((a, b) => (b.sound.type > a.sound.type ? 1 : -1));
                    }
                }
                if (sortBy == 'DB')
                {
                    if (sortReverse.value)
                    {
                        sortResult = modSearchResult.sort((a, b) => (a.sound.db > b.sound.db ? 1 : -1));
                    }
                    else
                    {
                        sortResult = modSearchResult.sort((a, b) => (b.sound.db > a.sound.db ? 1 : -1));
                    }
                }

                sortReverse.value = !sortReverse.value;
                loopArray.value = sortResult;

            };

            const changeSideList = (event) =>
            {
                sideListShown.value = !sideListShown.value;
            };

            const newSearch = (mw, mr, mi, inputSeason, inputPlacement) =>
            {
                w.value = mw;
                r.value = mr;
                i.value = mi;
                season.value = inputSeason;
                placement.value = inputPlacement;
                listLoading.value = true;
                filterArray.value = [];
                loopArray.value = [];
                fullArray.value = [];
                hiddenArray.value = [];

                if(inputPlacement !== '') {
                    loadData('/tyres?width=' + w.value + '&ratio=' + r.value + '&inches=' + i.value + '&season=' + inputSeason + '&placement=' + inputPlacement);
                } else {
                    loadData('/tyres?width=' + w.value + '&ratio=' + r.value + '&inches=' + i.value + '&season=' + inputSeason);
                }

            };

            const openList = (input, event) =>
            {
                data.value = loopArray.value[input];
                popupVisible.value = true;
            };

            const filterClick = (x, y) =>
            {

                let modFilter = JSON.parse(JSON.stringify(filterArray.value));
                let candidates = JSON.parse(JSON.stringify(fullArray.value));
                let selectCount = 0;

                if (y == 0)
                {
                    //console.log('Alle');

                    modFilter[x].data.forEach((key) =>
                    {
                        key.selected = false;
                    });
                    modFilter[x].data[0].selected = true;

                }
                else
                {
                    //console.log('Not Alle');
                    modFilter[x].data[0].selected = false;
                    if (modFilter[x].data[y].selected)
                    {
                        modFilter[x].data[y].selected = false;
                    }
                    else
                    {
                        modFilter[x].data[y].selected = true;
                    }
                }

                //console.log('selectCount: ', selectCount);
                modFilter[x].data.forEach((key) =>
                {
                    if (key.selected)
                    {
                        selectCount++;
                    }
                });

                if (selectCount == 0)
                {
                    modFilter[x].data[0].selected = true;
                }
                //console.log('selectCount: ', selectCount);

                filterArray.value = modFilter;
                let foundTotal = [];
                let foundThisLoop = [];
                let found = [];
                let avToSearch = candidates;

                modFilter.forEach((option, index) =>
                {

                    if(index >= 3) {
                        return; // lastbil nej tak
                    }

                    if (typeof option.data !== 'undefined') {
                        option.data.forEach((subOption, index2) =>
                        {

                            if (subOption.selected)
                            {
                                if (option.name == 'BrændstofØkonomi')
                                {
                                    if (subOption.type != 0)
                                    {
                                        found = avToSearch.filter(tire => tire.fuel.type == subOption.type);
                                    }
                                    else
                                    {
                                        found = avToSearch.filter(tire => tire);
                                    }

                                    if (found.length > 0)
                                    {
                                        // console.log('Fuel found', found);
                                        // console.log(found.length);
                                        // console.log(found.length > 0);
                                        foundThisLoop = foundThisLoop.concat(found);
                                    }
                                }
                                if (option.name == 'Vejgreb')
                                {
                                    if (subOption.type != 0)
                                    {
                                        found = avToSearch.filter(tire => tire.wet.type == subOption.type);
                                    } else
                                    {
                                        found = avToSearch.filter(tire => tire);
                                    }

                                    if (found.length > 0)
                                    {
                                        // console.log('Grip found', found);
                                        // console.log(found.length);
                                        // console.log(found.length > 0);
                                        foundThisLoop = foundThisLoop.concat(found);
                                    }

                                }
                                if (option.name == 'Støj')
                                {
                                    if (subOption.type != 0)
                                    {
                                        found = avToSearch.filter(tire => tire.sound.type == subOption.type);
                                    } else
                                    {
                                        found = avToSearch.filter(tire => tire);
                                    }

                                    if (found.length > 0)
                                    {
                                        // console.log('Noise found', found);
                                        // console.log(found.length);
                                        // console.log(found.length > 0);
                                        foundThisLoop = foundThisLoop.concat(found);
                                    }

                                }
                            }
                        });
                    }

                    loopArray.value = foundThisLoop;
                    foundTotal = [].concat(foundThisLoop);
                    avToSearch = [].concat(foundTotal);
                    foundThisLoop = [];
                });
            }

            const closeList = (input, event) =>
            {
                popupVisible.value = false;
            };

            const activeList = (input, event) => { };

            const expandList = (event) =>
            {
                listStatus.value = 2;
            };

            const openEquip = (event, latestInteraction) =>
            {
                listStatus.value = 4;
            };

            const constructUrl = async () =>
            {
                let apiUrl = "/tyres";

                if (typeof router.currentRoute.value.params.isPlate == "undefined")
                {
                    w.value = '205';
                    r.value = '55';
                    i.value = '16';
                    loadData(apiUrl + '?width=205&ratio=55&inches=16&season=S');
                }
                else if (router.currentRoute.value.params.isPlate == 'true')
                {
                    // console.log(router.currentRoute.value.params.plate);
                    // console.log(router.currentRoute.value.params.selectSeason);

                    isPlate.value = true;
                    plateNumber.value = router.currentRoute.value.params.plate;

                    season.value = router.currentRoute.value.params.selectSeason;

                    fetch(url + '/CarLookup/' + router.currentRoute.value.params.plate)
                        .then(response => response.json())
                        .then(data => {
                            car.value = data.BilMærke + " " + data.BilModel;
                            plateList.value = data.DaekStørrelser;
                            w.value = data.DaekStørrelser[0].Bredde;
                            r.value = data.DaekStørrelser[0].Profil;
                            i.value = data.DaekStørrelser[0].Tommer;
                            if(placement.value !== '') {
                                loadData(apiUrl + '?width=' + data.DaekStørrelser[0].Bredde + '&ratio=' + data.DaekStørrelser[0].Profil + '&inches=' + data.DaekStørrelser[0].Tommer + '&season=' + season.value + '&placement=' + placement.value)
                            } else {
                                loadData(apiUrl + '?width=' + data.DaekStørrelser[0].Bredde + '&ratio=' + data.DaekStørrelser[0].Profil + '&inches=' + data.DaekStørrelser[0].Tommer + '&season=' + season.value)
                            }
                        });
                } else {
                    w.value = router.currentRoute.value.params.selectWidth;
                    r.value = router.currentRoute.value.params.selectProfile;
                    i.value = router.currentRoute.value.params.selectInch;
                    season.value = router.currentRoute.value.params.selectSeason;

                    if(placement.value !== '') {
                        loadData(apiUrl + '?width=' + w.value + '&ratio=' + r.value + '&inches=' + i.value + '&season=' + season.value + '&placement=' + placement.value);
                    } else {
                        loadData(apiUrl + '?width=' + w.value + '&ratio=' + r.value + '&inches=' + i.value + '&season=' + season.value);
                    }
                }
            };

            const loadData = (apiUrl) =>
            {
                fetch(url + apiUrl)
                .then((response) => response.json())
                .then((data) =>
                {
                    loopArray.value = data;
                    fullArray.value = data;
                    loadFilters();
                });
            };

            const loadFilters = async () =>
            {
                let apiUrl;
                if(placement.value !== '') {
                    apiUrl = '/filters?width=' + w.value + '&ratio=' + r.value + '&inches=' + i.value + '&season=' + season.value + '&placement=' + placement.value;
                } else {
                    apiUrl = '/filters?width=' + w.value + '&ratio=' + r.value + '&inches=' + i.value + '&season=' + season.value;
                }

                fetch(url + apiUrl)
                    .then((response) => response.json())
                    .then((data2) =>
                    {
                        filterArray.value = data2;
                        listLoading.value = false;
                    });
            };

            onMounted(() =>
            {
                constructUrl();
            });

            return {
                activeList,
                callUrl,
                car,
                changeSideList,
                closeList,
                constructUrl,
                data,
                expandList,
                filterArray,
                filterClick,
                fullArray,
                hiddenArray,
                i,
                isPlate,
                listLoading,
                listNumber,
                listStatus,
                loadData,
                loadFilters,
                loopActive,
                loopArray,
                loopLastActive,
                newSearch,
                openEquip,
                openList,
                plateList,
                plateNumber,
                popupVisible,
                r,
                season,
                placement,
                sideListShown,
                sortArray,
                sortingArray,
                tireData,
                w,
            };
        },
    };
</script>
