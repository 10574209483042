import i18next from "i18next";
import backend from "i18next-fetch-backend";

export default {
  install: (app, options) => {
    i18next.use(backend).init({
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
      lng: "da",
      ns: "common",
      fallbackLng: "da",
      partialBundledLanguages: true,
      // debug: true,
      saveMissing: true,
    });
  },
};
