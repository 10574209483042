<template>
    <div class="menu" :class="isMenuOpen && ' menu--open'">

        <a class="menu-back" href="/">&#10094; start forfra</a>

        <h1 class="menu-headline" v-if="plateNumber!=''">{{ plateNumber }}</h1>
        <h1 class="menu-headline" v-if="plateNumber==''">Resultat af søgning</h1>

        <h2 class="menu-subheader">{{ car }}</h2>

        <p v-if="isPlate" class="label_i10">Dæk muligheder</p>
        <p v-if="!isPlate" class="label_i4">Bredde</p>
        <p v-if="!isPlate" class="label_i5">Profil</p>
        <p v-if="!isPlate" class="label_i6">Fælg</p>
        <select v-if="!isPlate" v-model="selectWidth" class="search_box search_box-4">
            <option v-for="(option, index) in arrayWidth" :key="'option-width-${index}'">
                {{ option }}
            </option>
        </select>
        <select v-if="!isPlate" v-model="selectProfile" class="search_box search_box-5">
            <option v-for="(option2, index2) in arrayProfile" :key="'option-width-${index2}'">
                {{ option2 }}
            </option>
        </select>
        <select v-if="!isPlate" v-model="selectInch" class="search_box search_box-6">
            <option v-for="(option3, index3) in arrayInch" :key="'option-width-${index3}'">
                {{ option3 }}
            </option>
        </select>
        <select v-if="isPlate" v-model="selectPlate" class="search_box search_box-7">
            <option v-for="(option4, index4) in plateList" :key="'option-width-${index4}'" :value="index4">
                {{ option4.Bredde + '/' + option4.Profil + 'R' + option4.Tommer }}
            </option>
        </select>

        <div class="winterWrap flex">

            <div class="mr-4" @click="setSummer()">
                <RadioButton :label="'Sommer'" :isChecked="selectSeason==='S'" />
            </div>

            <div class="mr-4" @click="setWinther()">
                <RadioButton :label="'Vinter'" :isChecked="selectSeason==='W'" />
            </div>

            <div class="mr-4" @click="setAllSeason()">
                <RadioButton :label="'Helårs'" :isChecked="selectSeason==='A'" />
            </div>

        </div>

        <div class="placement flex" v-if="showTruckOptions">

            <div class="mr-4" @click="setTruck('D')">
                <RadioButton :label="'Drive'" :isChecked="selectPlacement==='D'" />
            </div>

            <div class="mr-4" @click="setTruck('S')">
                <RadioButton :label="'Steering'" :isChecked="selectPlacement==='S'" />
            </div>

            <div class="mr-4" @click="setTruck('T')">
                <RadioButton :label="'Trailer'" :isChecked="selectPlacement==='T'" />
            </div>

        </div>

        <svg xmlns="http://www.w3.org/2000/svg"
             class="menu-filter-icon"
             enable-background="new 0 0 24 24"
             viewBox="0 0 24 24"
             @click="openListLocal()">
            <path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
                  fill="#bdbdbd" />
        </svg>

        <div class="menu-burger-icon" @click="openMenuLocal()">
            <div class="menu-burger-1"></div>
            <div class="menu-burger-2"></div>
            <div class="menu-burger-3"></div>
        </div>

        <div class="research_button" @click="reSearch()">Søg igen</div>

    </div>
</template>

<script lang="ts">
    import { onMounted, ref, watch } from "vue";
    import LargeInput from "/@components/LargeInput/LargeInput.vue";
    import CheckBox from "/@components/CheckBox/CheckBox.vue";
    import RadioButton from "/@components/RadioButton/RadioButton.vue";

    export default {
        components: {
            LargeInput,
            CheckBox,
            RadioButton
        },
        props: {
            url: {
                type: String,
                required: true,
            },
            w: {
                type: String,
            },
            r: {
                type: String,
            },
            i: {
                type: String,
            },
            car: {
                type: String,
                required: false
            },
            plateNumber: {
                type: String,
                required: false
            },
            seasonPick: {
                type: String,
            },
            placementPick: {
                type: String,
            },
            tireData: {
                type: Boolean,
                required: true,
            },
            isPlate: {
                type: Boolean,
                required: true,
            },
            plateList: {
                type: Array,
                required: true,
            },
            truckArray: {
                type: Array,
                required: true,
            }
        },
        emits: ["change-side-list", "new-search"],
        setup(props, { emit })
        {
            const arrayInch = ref([]);
            const arrayProfile = ref([]);
            const arrayWidth = ref([]);
            const isMenuOpen = ref(false);
            const selectInch = ref('');
            const selectPlate = ref('');
            const selectProfile = ref('');
            const selectSeason = ref('S');
            const selectPlacement = ref('');
            const selectWidth = ref('');
            const showTruckOptions = ref(false)

            const openListLocal = (input) =>
            {
                emit("change-side-list");
            };

            const openMenuLocal = (input) =>
            {
                isMenuOpen.value = !isMenuOpen.value;
            };

            const setSummer = () =>
            {
                selectSeason.value = 'S';
                reSearch();
            };

            const setWinther = () =>
            {
                selectSeason.value = 'W';
                reSearch();
            };

            const setAllSeason = () =>
            {
                selectSeason.value = 'A';
                reSearch();
            };

            const setTruck = (input) =>
            {
                if(selectPlacement.value == input) {
                    selectPlacement.value = '';
                     reSearch();
                } else {
                    selectPlacement.value = input;
                     reSearch();
                }
            };

            const reSearch = () =>
            {
                if (props.isPlate)
                {
                    let selected = selectPlate.value;
                    let plate = props.plateList[selected];
                    emit("new-search", plate.Bredde, plate.Profil, plate.Tommer, selectSeason.value, selectPlacement.value);
                } else
                {
                    emit("new-search", selectWidth.value, selectProfile.value, selectInch.value, selectSeason.value, selectPlacement.value);
                }

                checkTruck();
            };

            const getInitialData = () =>
            {
                fetch(props.url + '/TyreSelectors')
                .then(response => response.json())
                .then(data => (
                    arrayWidth.value = data.Bredder,
                    arrayProfile.value = data.Profiler,
                    arrayInch.value = data.Tommer,
                    setlData(props.w, props.r, props.i)
                ));
                checkTruck();
            };

            const checkTruck = () => {
                if(props.truckArray.length > 1) {
                    // if not truck, reset truck placement pick
                    if(!props.truckArray[3].show) {
                        selectPlacement.value = '';
                    }
                    // update truck option shown
                    showTruckOptions.value = props.truckArray[3].show;
                }
            };

            const setlData = (w, r, i) =>
            {

                if (w != "0")
                {
                    selectWidth.value = w;
                }
                else
                {
                    selectWidth.value = "205";
                };

                if (r != "0")
                {
                    selectProfile.value = r;
                }
                else
                {
                    selectProfile.value = "55";
                };

                if (i != "0")
                {
                    selectInch.value = i;
                }
                else
                {
                    selectInch.value = "16";
                };

            };

            onMounted(() =>
            {
                getInitialData();
            });

            watch([
                () => props.plateList, (count, prevCount) => {
                    selectPlate.value = 0;
                },

                () => props.filterArray,
                (count, prevCount) => {
                    checkTruck();
                },
                {deep: true},

            ]);

            return {
                arrayInch,
                arrayProfile,
                arrayWidth,
                checkTruck,
                getInitialData,
                isMenuOpen,
                openListLocal,
                openMenuLocal,
                reSearch,
                setTruck,
                selectInch,
                selectPlate,
                selectProfile,
                selectSeason,
                selectPlacement,
                showTruckOptions,
                selectWidth,
                setAllSeason,
                setlData,
                setSummer,
                setWinther
            };
        },
    };


</script>
<style src="./Menu.scss"></style>
