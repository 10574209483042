<template>
  <div
    :class="
      listLoading
        ? 'infi-load infi-load--open'
        : 'infi-load infi-load--closed'
    "
  >
    <h1 v-if="listLoading" class="infi-text">{{text}}</h1>
    <Spinner v-if="listLoading" />
    <p class="infi-load-text"><span class="infi-load-headline">Dækimportørforeningen</span>
Website: <a href="https://www.daekimportoerforeningen.dk/">Daekimportoerforeningen.dk</a><br>
E-mail: <a href="mailto:dbdk@dbdk.dk">dbdk@dbdk.dk</a></p>
    <!--<p class="infi-load-link infi-load-link--1">Du kan læse om hastighedskoder <a class="infi-load-link-a" href="https://www.daeklabel.dk/">her</a></p>
    <p class="infi-load-link infi-load-link--2">Du kan læse om belastningskoder <a class="infi-load-link-a" href="https://www.daeklabel.dk/">her</a></p>
    <p class="infi-load-link infi-load-link--3">Du kan læse om vinterdæk <a class="infi-load-link-a" href="https://www.daeklabel.dk/">her</a></p>-->
  </div>
</template>

<script lang="ts">
import { onMounted, ref} from "vue";
import Spinner from "/@components/Spinner/Spinner.vue";

export default {
  components: {
    Spinner,
  },
  props: {
    listLoading: Boolean,
    text: String,
  },
};

</script>
<style src="./InfiLoad.scss" scoped></style>
