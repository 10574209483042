<template>
  <div class="topmenu">
    <h1 class="topmenu-headline">Autodesktop</h1>
    <p class="topmenu-version">Version: 2.41</p>
    <div class="topmenu-list">
      <Badge :value="carTotal" />
    </div>
    <QuickMenu />
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import Badge from "/@components/Badge/Badge.vue";
import QuickMenu from "/@components/QuickMenu/QuickMenu.vue";

export default {
  components: {
    Badge,
    QuickMenu,
  },
  props: {
    carTotal: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style src="./Topmenu.scss" scoped></style>
