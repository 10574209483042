<template>
  <div class="popup">
    <div class="popup-wrap">
      <CloseIcon class="popup-close" @click="closeListLocal" />

      <h3 class="popup-headline">Dæk information</h3>
      <div class="popup-content">

        <div class="popup-content_element" v-for="(o, e) in data">
          <label class="popup-content_label">{{ o.label }}</label>
          <p p v-if="e == 'brand'" class="popup-content_text">{{o.text}}</p>
          <p v-if="e == 'description'" class="popup-content_text">{{ o.text }}</p>
          <p v-if="e == 'fuel'" class="popup-content_text">{{ o.text }}</p>
          <p v-if="e == 'wet'" class="popup-content_text">{{ o.text }}</p>
          <p v-if="e == 'sound'" class="popup-content_text">{{ o.text }}</p>
          <p v-if="e == 'ean'" class="popup-content_text">{{ o.text }}</p>
          <p v-if="e == 'placement'" class="popup-content_text">{{ o.text }}</p>
          <p v-if="e == 'listPrice'" class="popup-content_text">{{ o.text }}</p>
          <p v-if="e == 'speedCode'" class="popup-content_text">{{ o.text }}</p>
          <p v-if="e == 'loadIndex'" class="popup-content_text">{{ o.text }}</p>
          <p v-if="e == 'season'" class="popup-content_text">{{ o.text }}</p>
          <p v-if="e == '_3PMSF'" class="popup-content_text">{{ o.text }}</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CloseIcon from "/@components/CloseIcon/CloseIcon.vue";

export default {
  components: {
    CloseIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['close-list'],
  setup(props, { emit }){

    const closeListLocal = (input) => {
      emit('close-list');
    };

    return { closeListLocal };
  },
};
</script>
<style src="./PopUp.scss" scoped></style>
