<template>
  <div class="large-input_wrapper" :class="focusActive && 'large-input--focus', inputValueLocal.length != 0 && 'large-input--content'">
    <label :for="'large-input-' + inputID" class="large-input_label">{{label}}</label>
    <input type="text" :id="'large-input-' + inputID"
      class="large-input_input-1"
      :class="hasError && 'error'"
      v-bind="$attrs"
      v-model="inputValueLocal"
      @focus="focusActive = true"
      @blur="focusActive = false"
      @change="updateValue"
    />
    <div v-if="isRequired" :class="hasError ? 'large-input_req error' : 'large-input_req'" >*</div>
    <svg @click="clear()"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="svg"
    >
      <path class="svg-1" d="M0 0h24v24H0z" fill="none"/>
      <path class="svg-2" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
    </svg>
  </div>
</template>

<script>
import { computed, reactive, ref } from "vue";

export default {
  inheritAttrs : false,
  props: {
    label: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
      required: true,
    },
    isRequired: {
      type: Boolean,
    },
    inputValue: {
      type: String,
      required: false,
    },
    inputID: {
      type: String,
      required: true,
    },
  },
  emits: ['update-value'],
  setup(props, { emit }){
    const focusActive = ref(false);
    const inputValueLocal = ref(props.inputValue);

    const updateValue = (input) => {
      emit('update-value', inputValueLocal);
    };

    const clear = () => {
      inputValueLocal.value = ''
    };

    return {
      focusActive,
      inputValueLocal,
      updateValue,
      clear };
  },
};
</script>

<style src="./LargeInput.scss" scoped />
