<template>
  <div class="spinner"><div></div><div></div><div></div><div></div></div>
</template>

<script lang="ts">
import { onMounted, ref} from "vue";

export default {
};

</script>
<style src="./Spinner.scss" scoped></style>
