<template>
  <thead class="filter">
    <tr
      v-for="(sortData, index) in sortArray"
      :key="'sortData-' + index"
      class="list-element-data"
      @click="clickSort(index)"
      :class="sortData.reverse && ' list-element-data-r'"
    >
      <th class="list-element-text no-highlight" :class="sortData.center && ' list-element-center'" data-type="numeric">{{ sortData.name }}</th>
    </tr>
  </thead>
</template>

<script lang="ts">
import { onMounted, ref} from "vue";

  export default {
    props: {
      sortArray: Array,
    },
    emits: ["sub-sort-array"],
    setup(props, { emit }) {

      const clickSort = (index) => {
        emit("sub-sort-array", index);
      };

      return {
        clickSort,
      };
    },
  };

</script>
<style src="./Filter.scss" scoped></style>
