<template>
    <div class="content">
        <h3 class="input-headline">Find dæk til netop din bil</h3>
        <div class="input-box">
            <div class="input-box_content" v-if="!plateLoading">

                <p class="label_top">Vælg dæk type:</p>

                <div class="search_select">
                    <div class="search_select-cell" @click="setSummer()">
                        <RadioButton :label="'Sommer'" :isChecked="selectSeason == 'S'" />
                    </div>
                    <div class="search_select-cell" @click="setWinther()">
                        <RadioButton :label="'Vinter'" :isChecked="selectSeason == 'W'" />
                    </div>
                    <div class="search_select-cell" @click="setAllSeason()">
                        <RadioButton :label="'Helårs'" :isChecked="selectSeason == 'A'" />
                    </div>
                </div>

                <div class="search_seperator-1"></div>

                <p class="label_middle">Indtast din nummerplade:</p>
                <LargeInput
                    :label="'Nummerplade'"
                    :inputID="'plate-input'"
                    @update-value="updateValue"
                    :inputValue="plate"
                    :hasError="false"
                    :isRequired="false" />
                <h3 class="search_headline">(Kun person-/varebiler)</h3>
                <div class="search_button search_button-1" @click="getPlateData()">Søg på nummerplade</div>

                <div class="search_seperator-2"></div>

                <p class="label_bottom">Eller vælg dimensioner:</p>
                <p class="label_i1">Bredde</p>
                <p class="label_i2">Profil</p>
                <p class="label_i3">Fælg</p>

                <select v-model="selectWidth" class="search_box search_box-1">
                    <option v-for="(option, index) in arrayWidth" :key="'option-width-${index}'">
                        {{ option }}
                    </option>
                </select>

                <select v-model="selectProfile" class="search_box search_box-2">
                    <option v-for="(option2, index2) in arrayProfile"
                            :key="'option-width-${index2}'">
                        {{ option2 }}
                    </option>
                </select>

                <select v-model="selectInch" class="search_box search_box-3">
                    <option v-for="(option3, index3) in arrayInch" :key="'option-width-${index3}'">
                        {{ option3 }}
                    </option>
                </select>

                <div class="search_button search_button-2" @click="navNext()">Søg på dimensioner</div>

            </div>

            <div class="input-box_content" v-if="plateLoading">
                <InfiLoad :list-loading="true" :text="'Søger på nummerplade'" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { onMounted, ref } from "vue";
    import LargeInput from "/@components/LargeInput/LargeInput.vue";
    import InfiLoad from "/@components/InfiLoad/InfiLoad.vue";
    import RadioButton from "/@components/RadioButton/RadioButton.vue";
    import { router } from "./index";
    import { url } from "./index";

    export default {
        components: {
            LargeInput,
            InfiLoad,
            RadioButton
        },
        setup()
        {
            const arrayInch = ref([]);
            const arrayProfile = ref([]);
            const arrayWidth = ref([]);
            const plate = ref('');
            const plateLoading = ref(true);
            const selectInch = ref('');
            const selectProfile = ref('');
            const selectSeason = ref('S');
            const selectWidth = ref('');

            const getPlateData = () =>
            {
                plateLoading.value = true;
                router.push({
                    name: 'Daeklist',
                    params: {
                        isPlate: true,
                        plate: plate.value,
                        selectSeason: selectSeason.value,
                        selectWidth: 0,
                        selectProfile: 0,
                        selectInch: 0
                    }
                });
            };

            const navNext = () =>
            {
                router.push({
                    name: 'Daeklist',
                    params: {
                        isPlate: false,
                        plate: '',
                        selectInch: selectInch.value,
                        selectProfile: selectProfile.value,
                        selectSeason: selectSeason.value,
                        selectWidth: selectWidth.value
                    }
                });
            };

            const getInitialData = () =>
            {
                fetch(url + '/TyreSelectors')
                    .then(response => response.json())
                    .then(data => (
                        arrayWidth.value = data.Bredder,
                        arrayProfile.value = data.Profiler,
                        arrayInch.value = data.Tommer,
                        selectWidth.value = "205",
                        selectProfile.value = "55",
                        selectInch.value = "16",
                        plateLoading.value = false
                    ));
            };

            const updateValue = (input) =>
            {
                plate.value = input.value;
            };

            const setSummer = () =>
            {
                selectSeason.value = 'S';
            };

            const setWinther = () =>
            {
                selectSeason.value = 'W';
            };

            const setAllSeason = () =>
            {
                selectSeason.value = 'A';
            };

            onMounted(() =>
            {
                getInitialData();
            });

            return {
                arrayInch,
                arrayProfile,
                arrayWidth,
                getInitialData,
                getPlateData,
                navNext,
                plate,
                plateLoading,
                selectInch,
                selectProfile,
                selectWidth,
                selectSeason,
                setSummer,
                setWinther,
                setAllSeason,
                updateValue,
            };
        },
    };
</script>
