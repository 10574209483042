import { createRouter, createWebHistory } from "vue-router";
import DataInput from "./DataInput.vue";
import DaekList from "./DaekList.vue";

const routes = [
  {
    path: "/",
    name: "Datainput",
    component: DataInput,
  },
  {
    path: "/daeklist",
    name: "Daeklist",
    component: DaekList,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: 'is-active',
  routes,
});

//export const url = 'https://localhost:44370';
export const url = '';
//export const url = 'https://daekbranchen.autoit.dk';
