<template>
  <div class="close-icon"
    @click="click()"
    >
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="svg"
    >
      <path class="svg-1" d="M0 0h24v24H0z" fill="none"/>
      <path class="svg-2" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
    </svg>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";

export default {
  setup(props, { emit }){
    const click = () => {
      emit('close-click');
    };

    return { click };
  },
 };
</script>

<style src="./CloseIcon.scss" scoped></style>
