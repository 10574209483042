<template>
    <div class="input-grid_wrapper">
        <div class="radio_wrapper">
            <span class="text">{{label}}</span>
            <input type="radio" :checked="isChecked" :name="'radio' + label">
            <span class="checkmark"></span>
        </div>
    </div>
</template>

<script>
    import { computed, reactive } from "vue";

    export default {
        inheritAttrs: false,
        props: {
            label: {
                type: String,
                required: true,
            },
            isChecked: {
                type: Boolean,
                required: true,
            }
        }
    };


</script>

<style src="./RadioButton.scss" scoped />
