<template>
  <div class="layout">

    <router-view />

  </div>
</template>

<script lang="ts">
  import { ref } from "vue";
  export default { };
</script>

<style src="./App.scss"></style>
