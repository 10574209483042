import { computed, ref } from "vue";
import { router } from "/@src/views";
import oidc from "oidc-client";
const { UserManager } = oidc;
// OIDC giver ikke named exports. Der skulle angiveligt blive arbejdet på i næste mayor version af oidc-client.
// Så fra næste mayor version kan der laves den traditionelle: import { UserManager } from "oidc-client";

const userManager = new UserManager({
  authority: "https://devidentityserver.autoit.dk/",
  client_id: "vue",
  redirect_uri: `${window.location.href}auth/callback`,
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.href}auth/silent-renew`,
  response_type: "code",
  scope: "scope2",
  post_logout_redirect_uri: `${window.location.href}auth/logout`,
  filterProtocolClaims: true,
  revokeAccessTokenOnSignout: true,
  response_mode: "query",
});

export const accessToken = ref("");
export const isLoggedIn = computed(() => !!accessToken.value);

const setLogin = (user: oidc.User) => {
  accessToken.value = user?.access_token;
  // ??? GetRoles?
  // Also export roles to be used checked in AuthGuard
};

export const login = () => {
  userManager.getUser().then((user) => {
    setLogin(user);
  });
};

export const loginCallback = () => {
  userManager
    .signinRedirectCallback()
    .then(function (user) {
      setLogin(user);
      router.push("../");
    })
    .catch(function (err) {});
};

export const silentLoginCallback = () => {
  userManager.signinSilentCallback();
};

export const logoutCallback = () => {
  userManager.removeUser().then(() => {
    accessToken.value = "";
    router.push("../");
  });
};

export const loginRedirect = () => {
  userManager.signinRedirect();
};

export const logoutRedirect = () => {
  userManager.signoutRedirect();
};
