<template>
  <div class="badge">
    <span class="badge__text">{{ thousandSeparator(displayNumber) }}</span>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { thousandSeparator } from "/@utils/thousandSeparator";

export default {
  props: {
    value: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data: function() {
    return {
      displayNumber: this.value ? this.value : 0,
      interval: false,
    }
  },
  watch: {
    carTotal: function() {
      clearInterval(this.interval)

      if (this.value === this.displayNumber) {
        return
      }

      this.interval = window.setInterval(
        function() {
          if (this.displayNumber !== this.value) {
            let change = (this.value - this.displayNumber) / 8
            change = change >= 0 ? Math.ceil(change) : Math.floor(change)
            this.displayNumber = this.displayNumber + change
          } else {
            clearInterval(this.interval)
          }
        }.bind(this),
        12
      )
    },
  },
  methods: {
    thousandSeparator(value) {
      return thousandSeparator(value)
    },
  },
};
</script>
<style lang="scss" src="./Badge.scss" scoped></style>
