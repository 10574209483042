<template>
  <div class="list scrollbar-style" @scroll="onScroll">
    <div
      class="right-click-menu scrollbar-style"
      ref="rightClickMenu"
      tabindex="-1"
      v-if="viewMenu"
      @blur="closeMenu"
      :style="{ top: top, left: left }"
    >
      <div class="right-click-menu_element" @click="deleteCar">Fjern bil fra listen</div>
      <div class="right-click-menu_element right-click-menu_bottom" @click="carIsSold">
        Bilen er solgt
      </div>
      <div class="right-click-menu_element" @click="copyEquipment">Kopier udstyr</div>
      <div class="right-click-menu_element">Opret tilbud</div>
      <div class="right-click-menu_element">Tegn forsikring</div>
      <div class="right-click-menu_element">Prøvekørsel</div>
      <div class="right-click-menu_element">Genberegn</div>
      <div class="right-click-menu_element">Find tilsvarende</div>
    </div>

    <table v-if="!listLoading">
      <Filter
        :sort-array="sortArray"
        @sub-sort-array="subSortArray"
      />
      <tbody>
        <tr
          v-for="(listData, index2) in loopArray"
          :key="'listData-' + index2"
          class="list-element"
          :class="listData.selected && 'list-element-selected'"
          @click="openListLocal(index2)"
        >
          <td class="list-element_text no-highlight">{{ listData.brand.text }}</td>
          <td class="list-element_text no-highlight">{{ listData.description.text }}</td>
          <td class="list-element_text no-highlight" :class="'list-element_type--' + listData.fuel.type"><span class="list-element_bg">{{ listData.fuel.type }}</span></td>
          <td class="list-element_text no-highlight" :class="'list-element_type--' + listData.wet.type"><span class="list-element_bg">{{ listData.wet.type }}</span></td>
          <td class="list-element_text no-highlight" :class="'list-element_type--sound-' + listData.sound.type"><span class="list-element_bg">{{ listData.sound.type }}</span></td>
          <td class="list-element_text no-highlight list-element_text-center">{{ listData.sound.db }}</td>
        </tr>
      </tbody>
    </table>

    <InfiLoad :list-loading="listLoading" :text="'Henter dæk data...'" />
  </div>
</template>

<script lang="ts">
  import { onMounted, ref } from "vue";
  import InfiLoad from "/@components/InfiLoad/InfiLoad.vue";
  import Search from "/@components/Search/Search.vue";
  import Filter from "/@components/Filter/Filter.vue";

  export default {
    components: {
      InfiLoad,
      Filter,
    },
    props: {
      listStatus: Number,
      loopArray: Array,
      listLoading: Boolean,
      sortArray: Array,
    },
    emits: ["open-list", "load-more", "sorting-array"],
    setup(props, { emit }) {
      const viewMenu = ref(false);
      const top = ref("0px");
      const left = ref("0px");
      const latestInteraction = ref(-1);
      const rightClickMenu = ref(null);

      const closeMenu = () => {
        viewMenu.value = false;
      };

      const openListLocal = (input) => {
        emit("open-list", input);
      };

      const onScroll = ({ target: { scrollTop, clientHeight, scrollHeight } }) => {
        if (scrollTop + clientHeight >= scrollHeight) {
          emit("load-more");
        }
      };

      const deleteCar = () => {
        window.confirm("Er du sikker?");
      };

      const carIsSold = () => {
        alert("Bil flyttet til solgt");
      };

      const copyEquipment = () => {
        emit("open-equip", latestInteraction);
      };

      const subSortArray = (index) => {
        emit("sorting-array", index);
      };

      return {
        openListLocal,
        onScroll,
        closeMenu,
        viewMenu,
        top,
        left,
        rightClickMenu,
        latestInteraction,
        deleteCar,
        carIsSold,
        copyEquipment,
        subSortArray,
      };
    },
  };
</script>
<style src="./List.scss" scoped></style>
